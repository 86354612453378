.challenge-modal {
  display: none;
  position: fixed;
  inset: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .65);
  // overflow: auto;
  &.open {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-body {
    border-radius: 20px;;
    background: #fff;
    height: 100%;
    width: 100%;
    max-width: 1024px;
    max-height: 750px;
    overflow:scroll;
  }
  @media (max-width: 400px) {
    .modal-body {
      max-width: 100%;
      max-height: 100%;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}