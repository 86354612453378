:root {
  //--page-margin: var(--app-broad-margin);
  // --page-background: var(--app-background);

  /* Set the font family of the entire app */
  --ion-font-family: 'Barlow Semi Condensed';
  // --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;


}

body {
  font-family: 'Barlow Semi Condensed';
}

ion-button {
  font-family: 'Barlow Semi Condensed', sans-serif;
}

// Animation css
@import '~animate.css/animate.min.css';

.form-content {
  --background: var(--page-background);
  --padding-start: var(--page-margin);
  --padding-end: var(--page-margin);
  --padding-top: var(--page-margin);
  --padding-bottom: var(--page-margin);

  .auth-title {
    font-weight: bolder;
  }

  .inputs-list {
    --ion-item-background: var(--page-background);

    ion-item {
      --border-color: var(--ion-color-primary);
    }

    .label_item {
      color: var(--ion-color-primary);
    }

    .input-item {
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
    }

    .error-container {
      .error-message {
        margin: calc(var(--page-margin) / 2) 0px;
        display: flex;
        align-items: center;
        color: var(--ion-color-danger);
        font-size: 18px;

        ion-icon {
          padding-inline-end: calc(var(--page-margin) / 2);
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 768px) {
      .error-container {
        .error-message {
          font-size: 14px;
        }
      }
    }
  }
}

.global-error-container {
  .error-message {
    padding-left: 20px;
    margin: calc(var(--page-margin) / 2) 0px;
    display: flex;
    align-items: center;
    color: var(--ion-color-danger);
    font-size: 14px;

    ion-icon {
      padding-inline-end: calc(var(--page-margin) / 2);
    }
  }
}

.line {
  width: 100%;
  border: 2px solid var(--ion-color-primary);
}

@media (min-width: 576px) {
  .padding-desktop {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.main-wrapper {
  max-width: 1400px;
  margin: auto;
}

@media only screen and (min-width: 1400px) {
  .main-wrapper {
    padding-inline: 60px;
  }
}

@media only screen and (max-width: 1399px) {
  .main-wrapper {
    padding-inline: 20px;
  }
}

.link-container {
  div {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
  }

  a {
    color: black;
  }

  a:hover {
    color: var(--ion-color-primary);
  }
}

ion-modal.auto-height {
  --height: auto;

  &::part(content) {
    //width: 70vw;
    overflow: auto;
    position: relative;
    contain: content;
    max-height: 70vh;

    .modal-content {
      overflow: auto;
    }
  }
}

@media (max-width: 576px) {
  ion-modal.auto-height {
    &::part(content) {
      overflow: auto;
      max-height: 100vh;
    }
  }
}

ion-toolbar {
  --padding-start: 10px;
  --padding-end: 10px;
}


//__Color Status
$stat-yellow: #ffc933ff;
$stat-dark-orange: rgb(223, 95, 35);
$stat-blue: #1778b5ff;
$stat-darkblue: #10547eff;
$stat-green: #76ab21ff;
$stat-red: #e00007ff;
$stat-lightgray: #d8e7e9ff;
$stat-purple: #7453a2ff;
$stat-fuchsia: #ff00ac;

$comission-pink: #FFC5E2;
$comission-blue: #C1ECFF;
$comission-yellow: #FFECBA;
$comission-black: #4F4F4F;


.commission-type.wallet {
  color: $comission-black;
}

.commission-type.bank {
  color: $comission-yellow;
}

.commission-type.fee {
  color: $comission-blue;
}

.commission-type.rollback {
  color: $comission-pink;
}

// Commission Ref
// Text
.commission-status-complete {
  color: $stat-purple;
}

.commission-status-rejected {
  color: $stat-dark-orange;
}

// container
.commission-status.pending {
  background-color: $stat-yellow;
}

.commission-status.complete {
  background-color: $stat-purple;
}

.commission-status.rejected {
  background-color: $stat-dark-orange;
}

.commission-status.expired {
  background-color: $stat-lightgray;
}

.commission-status.error {
  background-color: $stat-red;
}

// ion-button{
//   --border-radius: 50px;
// }

.print-setting-badges {
  --background: white;
  --color: var(--ion-color-medium);
  border: 1px solid var(--ion-color-medium);
  margin-right: 5px;
  margin-bottom: 5px;;
}

.divisor {
  border-bottom: 0.5px solid;
  height: 1px;
  width: 100%;
  border-color: rgb(204, 204, 204);
}
