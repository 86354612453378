@mixin ion-color($color) {
  @content;

  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color}) !important;
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
    --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
    --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
  }
}
